import "./App.css";

import {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from "./components/Dropdown";
import Table from "./components/Table";
import Pagination from "./components/Pagination";
import ReconnectingWebSocket from "reconnecting-websocket";
import { addAndUpdateTableData, PAGE_LIMIT_OPTIONS, webSocketApi } from "./constants/actionTypes";
import SearchInput from "./components/Filters";
import { useGetQueueInfoQuery } from "./components/Api/pcService";
import Widget from "../src/components/Widget/index";
import { getSelectedIdsForUser } from "./utils/storageUtils";
import StandbyAlert from "./components/StandByAlert";

const columns = [
  { id: "order_number", label: "Order #", minWidth: 211, align: "text-right text-blue-800 underline", headerAlign: "text-right" },
  { id: "id", label: "Job #", minWidth: 211, align: "text-right text-blue-800 underline", headerAlign: "text-right" },
  { id: "pcq_status", label: "Approved", minWidth: 211, align: "text-center", headerAlign: "text-center" },
  { id: "adjustment_needed", label: "Price Flag", minWidth: 211, align: "text-center", headerAlign: "text-center" },
  { id: "order_created_on_date", label: "Ordered On", minWidth: 73, align: "text-right", headerAlign: "text-right" },
  { id: "total_price", label: "Price", minWidth: 73, align: "text-right", headerAlign: "text-right" },
  { id: "order_status", label: "Order Status", minWidth: 200, align: "text-left", headerAlign: "text-left" },
  { id: "turn_time", label: "Turn Time", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "product_type", label: "Product Type", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "proof_status", label: "Proof Status", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "last_log_update", label: "Last Log Update", minWidth: 120, align: "text-right", headerAlign: "text-right" },
  { id: "ship_date", label: "Ship Date", minWidth: 120, align: "text-right", headerAlign: "text-right" },
  { id: "pc", label: "PC", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "location_name", label: "Location", minWidth: 120, align: "text-left", headerAlign: "text-left" }
];

const App = () => {
  const auth = useSelector((state) => state.auth);
  const user = `${auth.firstName} ${auth.lastName}`;
  const dispatch = useDispatch();
  const [allPcData, setAllPcData] = useState([{user_id:user, PC: user}]);
  const [pcData, setPcData] = useState([{user_id:user, PC: user}]);
  const selectedIds = getSelectedIdsForUser(user);
  const initialRowsData = useSelector((state) =>
    state.tableData.data.filter((row) => selectedIds.includes(row.attributes.pc))
  ) || [];
  const tableRowsData = useSelector((state) => state.tableData.data) || [];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPcs, setSelectedPcs] = useState([]);
  const [pageSize] = useState(PAGE_LIMIT_OPTIONS[0].id);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [jobNumberSearchValue, setJobNumberSearchValue] = useState('');
  const [orderNumberSearchValue, setOrderNumberSearchValue] = useState('');
  const [num, setNum] = useState(0);
  const [activeTabId, setActiveTabId] = useState(1);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [quickFilterColumn, setQuickFilterColumn] = useState(null);
  const [quickFilterKey, setQuickFilterKey] = useState(null);

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  const { data: apiData } = useGetQueueInfoQuery();

  const formattingJobIdData = (data) => {
    return data.map(item => {
      if (item.attributes && typeof item.attributes.id !== 'string') {
        item.attributes.id = String(item.attributes.id)
            .split('')
            .reverse()
            .join('')
            .replace(/00000000/, '-')
            .split('')
            .reverse()
            .join('');
      }
      return item;
    });
  };

  useEffect(() => {
    if (apiData) {
      const signedUser = { user_id: user, PC: user };
      const updatedApiData = apiData.some((value) => signedUser.PC.includes(value.PC)) ? apiData : [...apiData, signedUser];
      setPcData(updatedApiData);
      setAllPcData(updatedApiData);
    }
  }, [user,apiData]);




  useEffect(() => {
    const socket = new ReconnectingWebSocket(webSocketApi);

    socket.onopen = () => {
      console.log("WebSocket connection established.");
      const data = {
        action: "default",
      };
      try {
        socket.send(JSON.stringify(data));
      } catch (error) {
        console.error("WebSocket send error:", error);
      }
    };

    socket.onmessage = (event) => {
      let data = JSON.parse(event.data);
      const updatedData = formattingJobIdData(data);
      dispatch(addAndUpdateTableData(updatedData));
      setLoading(false);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setLoading(true);
      setError("Failed to receive data from WebSocket");
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
      setLoading(false);
      const disconnect = {
        action: "disconnect",
      };
      try {
        socket.send(JSON.stringify(disconnect));
      } catch (error) {
        console.error("WebSocket send error:", error);
      }
    };

    return () => {
      socket.close();
    };

  }, [dispatch]);


  useEffect(() => {
    if (tableRowsData.length > 0 ) {
      setLoading(false);
    }
  }, [tableRowsData.length]);

  const handleChange = (selectedValues) => {
    setNum(1);
    const updatedPcData = allPcData.filter((value) => selectedValues.includes(value.PC));
    setSelectedPcs(updatedPcData);

    const filteredData = tableRowsData.filter((row) =>
      updatedPcData.some((value) => value.PC === row.attributes.pc)
    );
    setSortedData(filteredData);
    setCurrentPage(1);
    setIsClearFilter(false);
  };

  const handleSearchChange = (event) => {
    setJobNumberSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const handleOrderNumberSearchChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    setOrderNumberSearchValue(numericValue);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setJobNumberSearchValue('');
    setOrderNumberSearchValue('');
  };

  let dataToFilter;
  if (num === 0 && initialRowsData.length !== 0) {
    dataToFilter = selectedPcs.length > 0 ? sortedData : initialRowsData;
  } else if (num === 0 && initialRowsData.length === 0) {
    dataToFilter = [];
  } else {
    dataToFilter = selectedPcs.length > 0 ? sortedData : tableRowsData;
  }

  const getIndividualCardCount = (columnName, keys) => {
    const filteredData = columnName && keys && keys.length
      ? dataToFilter.filter(row => {
        if (columnName === "proof_status") {
          return keys.includes(row.attributes[columnName]) && row.attributes["production_status_name"] === "Not Set"
              && row.attributes["pcq_status"] === "1";
        } else if(columnName === "adjustment_needed") {
          return keys.includes(row.attributes[columnName]) && row.attributes["pcq_status"] === "1";
        } else {
          return keys.includes(row.attributes[columnName]);
        }
      })
      : dataToFilter;

    return filteredData.length || 0;
  };


  const handleQuickFilter = (id, columnName, keys) => {
    setQuickFilterColumn(columnName);
    setQuickFilterKey(keys);

    setActiveTabId(id);
    handleClearSearch();
  }

  const filteredTableRowsData = dataToFilter.filter((row) =>
      String(row.attributes.id).includes(jobNumberSearchValue) &&
      String(row.attributes.order_number).includes(orderNumberSearchValue)
  );

  const filteredTableRowsWithQuickFilter = quickFilterColumn && quickFilterKey
    ? filteredTableRowsData.filter(row => {
      if (quickFilterColumn === "proof_status") {
        return quickFilterKey.some(key =>
          row.attributes[quickFilterColumn].includes(key) && row.attributes["production_status_name"] === "Not Set"
            && row.attributes["pcq_status"] === "1"
        );
      } else if (quickFilterColumn === "adjustment_needed") {
        return quickFilterKey.some(key =>
            row.attributes[quickFilterColumn].includes(key) && row.attributes["pcq_status"] === "1"
        );
      } else {
        return quickFilterKey.some(key => String(row.attributes[quickFilterColumn]).includes(key));
      }
    })
    : filteredTableRowsData;


  return (
    <div className="p-8">
      <h1>Project Coordinator Queue</h1>
      <Dropdown
        className="mr-5"
        label="Project Coordinator"
        options={pcData}
        onChange={handleChange}
        isClearFilter={isClearFilter}
      />
      <StandbyAlert />
      <Widget
        activeTabId={activeTabId}
        options={[
          { id: 1, label: "All Active Jobs", count: getIndividualCardCount(), onClick: (id) => handleQuickFilter(id) },
          { id: 2, label: "Production status not set", count: getIndividualCardCount("production_status_name", ["Not Set"]),
            onClick: (id) => handleQuickFilter(id, "production_status_name", ["Not Set"]) },
          { id: 3, label: "All Approved", count: getIndividualCardCount("proof_status", ["Approved"]),
            onClick: (id) => handleQuickFilter(id, "proof_status", ["Approved"]) },
          { id: 4, label: "In Production", count: getIndividualCardCount("production_status_name",
              ["Production Ready", "Queued For Print", "Printed","Finished"]),
            onClick: (id) => handleQuickFilter(id, "production_status_name",
              ["Production Ready", "Queued For Print", "Printed","Finished"]) },
          { id: 5, label: "Price Adjustments", count: getIndividualCardCount("adjustment_needed",
              ["true"]),
            onClick: (id) => handleQuickFilter(id, "adjustment_needed",
              ["true"]) },
          { id: 6, label: "Split Shipping", count: getIndividualCardCount("split_shipment_alert",
              [1]),
            onClick: (id) => handleQuickFilter(id, "split_shipment_alert",
              [1]) }
        ]}
      />
      <div className="flex justify-between">
        <div className="flex items-center">
          <SearchInput
            value={orderNumberSearchValue}
            onChange={handleOrderNumberSearchChange}
            placeholder="Order #"
          />
          <SearchInput
            value={jobNumberSearchValue}
            onChange={handleSearchChange}
            placeholder="Job #"
          />
          <button
            className="text-sm text-[#506986] cursor-pointer ml-2 mt-7 underline hover:text-gray-700 focus:outline-none "
            onClick={handleClearSearch}
          >
            Clear
          </button>
        </div>
        <div className="flex items-center">
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalRows={filteredTableRowsWithQuickFilter.length}
            totalPages={Math.ceil(filteredTableRowsWithQuickFilter.length / pageSize)}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <Table
        className="mb-2"
        currentPage={currentPage}
        pageSize={pageSize}
        rowsData={filteredTableRowsWithQuickFilter}
        columns={columns}
        loading={loading}
        error={error}
      />
      <div className="flex justify-end">
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalRows={filteredTableRowsWithQuickFilter.length}
          totalPages={Math.ceil(filteredTableRowsWithQuickFilter.length / pageSize)}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default App;
